<template>
  <ERow justify="center" class="emailing h-100">
    <ECol cols="12" class="pt-0">
      <ERow justify="center" class="flex-nowrap">
        <ECol cols="3" class="pt-0 pr-2 pl-4">
          <!-- TEMPLATE -->
          <ValidationObserver>
            <v-card flat>
              <v-card-title> Template </v-card-title>
              <v-card-text>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Template"
                  rules="required"
                >
                  <v-select
                    v-model="template"
                    :items="templates"
                    label="Template"
                    :error-messages="errors"
                    outlined
                    dense
                    class="evercam-field"
                  >
                  </v-select>
                </ValidationProvider>
                <div
                  v-for="field in templateFields[template]"
                  :key="`${template}-${field.name}`"
                >
                  <div v-if="field.type === 'editor'" class="mb-5">
                    <v-card-text class="pa-0 pb-1">
                      <span>
                        {{ field.name }}
                      </span>
                    </v-card-text>
                    <QuillEditor v-model="field.value" light />
                  </div>
                  <v-text-field
                    v-else
                    v-model="field.value"
                    :label="field.name"
                    dense
                    outlined
                  >
                  </v-text-field>
                </div>

                <v-btn
                  v-if="templateFields[template].length"
                  color="primary"
                  class="w-100"
                  :disabled="!isPreviewFormValid"
                  @click="refreshPreview"
                >
                  preview
                  <v-icon small class="ml-2"> fa-sync-alt </v-icon>
                </v-btn>
              </v-card-text>
            </v-card>

            <v-divider></v-divider>

            <!-- PARAMS -->
            <v-card flat>
              <v-card-title> Params </v-card-title>

              <v-card-text>
                <span> Subject * </span>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Subject"
                  rules="required"
                >
                  <v-text-field
                    v-model="emailingStore.subject"
                    label="Subject"
                    :error-messages="errors"
                    outlined
                    dense
                    class="evercam-field"
                  >
                  </v-text-field>
                </ValidationProvider>
                <span> Sender * </span>
                <Autocomplete
                  v-model="emailingStore.sender"
                  item-value="email"
                  item-text="email"
                  label="Sender Email"
                  clearable
                  attach
                  rules="required"
                  :provider="provider"
                  :provider-params="ownerParams"
                />
                <span> Tags </span>
                <v-combobox
                  v-model="tags"
                  label="Tags"
                  multiple
                  chips
                  deletable-chips
                  outlined
                  dense
                  class="evercam-field"
                >
                </v-combobox>
              </v-card-text>
            </v-card>

            <v-divider></v-divider>

            <!-- RECIPIENTS -->
            <v-card flat>
              <v-card-title> Recipients * </v-card-title>
              <v-card-text>
                <div class="mb-4">
                  <EmailingRecipientsChip
                    v-for="{
                      id,
                      exid,
                      name,
                      fullname,
                      email,
                    } in recipientItems"
                    :key="id"
                    :text="exid ? name : fullname"
                    :caption="exid || email"
                    :deletable="false"
                  />
                  <span v-if="!recipientItems.length"> (empty) </span>
                </div>
                <v-btn
                  color="primary"
                  class="w-100"
                  @click="emailingStore.recipientsDialog = true"
                >
                  Edit
                  <v-icon small class="ml-1">fa-edit</v-icon>
                </v-btn>
              </v-card-text>
            </v-card>
          </ValidationObserver>
          <EmailingRecipientsDialog />
        </ECol>
        <v-divider vertical></v-divider>
        <ECol cols="9" class="pt-0">
          <v-card flat>
            <v-card-title class="pb-0">
              <ERow justify="center" class="red--text" no-gutters>
                <v-icon class="mr-3" color="red">
                  fa-exclamation-triangle
                </v-icon>
                <h2>real emails will be sent to customers</h2>
                <v-icon class="ml-3" color="red">
                  fa-exclamation-triangle
                </v-icon>
              </ERow>
            </v-card-title>
            <v-card-title class="pt-0">
              <ERow no-gutters justify="between"> Preview </ERow>
            </v-card-title>
            <v-card-text>
              <iframe
                ref="previewIframe"
                class="preview-iframe"
                :src="previewURL"
              ></iframe>
            </v-card-text>
          </v-card>
        </ECol>
      </ERow>
    </ECol>
    <ERow align="end" justify="end" no-gutters>
      <div class="mr-5 pr-2 mb-5 pb-2">
        <v-btn
          color="primary"
          :loading="isSending"
          :disabled="!isFormValid"
          @click="onSendEmails"
        >
          Send
          <v-icon small class="ml-2"> far fa-paper-plane </v-icon>
        </v-btn>
      </div>
    </ERow>
  </ERow>
</template>

<script>
import QuillEditor from "@/components/quillEditor/QuillEditor"
import Autocomplete from "@evercam/shared/components/Autocomplete"
import EmailingRecipientsChip from "@/components/emailing/EmailingRecipientsChip"
import EmailingRecipientsDialog from "@/components/emailing/EmailingRecipientsDialog"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useEmailingStore } from "@/stores/emailing"
import { useAccountStore } from "@/stores/account"

export default {
  name: "Emailing",
  components: {
    QuillEditor,
    Autocomplete,
    EmailingRecipientsChip,
    EmailingRecipientsDialog,
  },
  data() {
    return {
      previewURL: "",
      tags: [],
      template: "timelapse",
      templateFields: {
        timelapse: [
          {
            name: "custom_text",
            value: "We're excited to share your time-lapse video with you!",
            type: "editor",
          },
        ],
        template2: [],
      },
      templates: ["timelapse", "template2"],
      isSending: false,
    }
  },
  head() {
    return {
      title: "Admin - Emailing",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useEmailingStore, useAccountStore),
    provider() {
      return AdminApi.users.getAdmins
    },
    recipientItems() {
      return [
        ...this.emailingStore.selectedUsers,
        ...this.emailingStore.selectedProjects,
        ...this.emailingStore.selectedCameras,
      ]
    },
    isPreviewFormValid() {
      return !!this.templateFields[this.template].reduce(
        (acc, field) => acc + field.value,
        ""
      )
    },
    templateForm() {
      return this.templateFields[this.template].reduce(
        (acc, { name, value }) => {
          if (value === "") {
            return acc
          }

          return {
            ...acc,
            [name]: value,
          }
        },
        {}
      )
    },
    isFormValid() {
      return (
        this.emailingStore.sender &&
        this.emailingStore.subject &&
        Object.values(this.emailingStore.recipients).length
      )
    },
  },
  mounted() {
    this.refreshPreview()
    this.emailingStore.$reset()
  },
  methods: {
    refreshPreview() {
      this.previewURL = `${this.$config.public.apiURL}/admin/mailing/${
        this.template
      }/preview?${this.toQueryString({
        authorization: this.accountStore.token,
        ...this.emailingStore.recipients,
        ...this.templateForm,
      })}`
    },
    ownerParams(val) {
      return {
        sort: "email|asc",
        perPage: 50,
        page: 1,
        email: val,
      }
    },
    onSendEmails() {
      this.isSending = true
      this.emailingStore
        .performEmailing({
          template: this.template,
          params: {
            from: this.emailingStore.sender.id,
            to: this.emailingStore.recipients,
            subject: this.emailingStore.subject,
            tags: this.tags,
            ...this.templateForm,
          },
        })
        .finally(() => (this.isSending = false))
    },
  },
}
</script>

<style lang="scss">
.emailing {
  .preview-iframe {
    width: 100%;
    min-height: 621px;
    border: 1px solid #d0d0d0;
    border-radius: 3px;
  }
  .evercam-field {
    & .v-input__slot {
      box-shadow: none !important;
    }
  }
}
</style>
